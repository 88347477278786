import { useRef } from "react";
import "./index.css"
export default function Search(){
    const inputRef = useRef<HTMLInputElement>(null) ;

    function GO(go:string){
        // GO:string){
        switch (go){
            /* 
            搜索页：
              https://cn.bing.com/search?q
              https://www.google.com/search?q
              https://www.baidu.com/s?wd
          */
            case "百度":
                //openwinow 跳转
                window.open(`https://www.baidu.com/s?wd=${inputRef.current?.value}`);
                break;
            case "谷歌":
                window.open(`https://www.google.com/search?q=${inputRef.current?.value}`);
                break;
            case "必应":
                window.open(`https://cn.bing.com/search?q=${inputRef.current?.value}`);
                break
            }
    }

    const clickGo = () => {
        const go = document.querySelector('ul')?.querySelector('.list-item')?.querySelector("span")?.innerText 
        if (go){
            //if在这里的意义：
            //typescript的语法检查相当严格 innerText存在undefined的可能性 在编译时会报错
            //当if判断不为undefined的时候就没事了 
            GO(go)
        }
    }

    const handleGo = (event:React.KeyboardEvent<HTMLInputElement>) => { 
        if(event.key == 'Enter'){
            clickGo()
        }
    };

    return(
        //搜索框
        <div className='Search'>
          <div id='select'>
            <ul className='list' onMouseDown=
            {
                 (event: React.MouseEvent<HTMLElement>) => {
                /*
                    就是喜欢这种在HTML元素里穿插JS的不当人写法 （溜
                */
                    const target = event.target as HTMLUListElement;
                    // //这样的话 如果点击了空白的地方就不行了=> 随意清空
                    // event.currentTarget.querySelectorAll('li').forEach( (el) => {
                    //     el.className = ""
                    // })
                    event.currentTarget.querySelectorAll('li').forEach( (el) => {
                        if(target.tagName == "SPAN"){
                            //判断是不是点到该位置
                            if(target.innerText == el.innerText){
                                el.className = "list-item"
                            } else{
                                el.className = ""
                            }
                        }
                    })
                }
            }
            >
                <li className='list-item' ><span>必应</span></li>
                <li className=''><span>谷歌</span></li>
                <li className=''><span>百度</span></li>
              </ul>
          </div>
          
          {/* <form action="" method="get" target="_blank"> */}
            <input  ref={inputRef} type="text" name="q"  onKeyDown={handleGo}/>
            <button onClick={clickGo}><i>Go</i></button>
          {/* </form> */}
        </div>
    );
}

