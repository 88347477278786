import React from 'react';
import ReactDOM from 'react-dom/client';
import Search from './Components/Search';
import Bg from './Components/Bg';
import "./index.css"
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <div className="App">
        <Bg />
        <div className='Logo'></div>
        <Search/>
    </div>
  </React.StrictMode>
);
